<template>
  <el-dialog
    :append-to-body="true"
    v-if="dialog"
    :visible.sync="dialog"
    :title="title"
    width="1000px"
  >
    <el-form
      ref="elform"
      :model="formData"
      :rules="rules"
      label-position="right"
      label-width="120px"
      hide-required-asterisk
    >
      <el-form-item label="描述" prop="desc">
        <el-input
          style="width: 370px;"
          v-model="formData.desc"
          placeholder="请输入"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item
        label="发件人"
        prop="emailConfigId"
      >
        <el-select
          v-model="formData.emailConfigId"
          placeholder="请选择"
          style="width: 370px;"
        >
          <el-option
            v-for="(item,index) in fromEmailList"
            :key="index"
            :label="getLabel(item)"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="收件人"
        prop="recipientsType"
      >
        <div class="type-check">
          <el-radio
                  @change="typeChange"
            v-model="formData.recipientsType"
            :label="1"
          >类型</el-radio>
          <el-radio
                  @change="typeChange"
            v-model="formData.recipientsType"
            :label="2"
          >自定义</el-radio>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="select-type-module">
          <el-row :gutter="10">
            <el-col :span="22">
              <el-form-item v-if="formData.recipientsType == 1" prop="typeValue">
<!-- formData.typeValue: [[99,1],[99,2],]-->
                <el-cascader
                  v-model="formData.typeValue"
                  style="width: 100%;"
                  placeholder="请选择"
                  :options="cascaderOption"
                  :props="props"
                  @remove-tag="elCascaderRemoveHandle"
                  clearable
                ></el-cascader>
              </el-form-item>
              <el-form-item v-if="formData.recipientsType == 2"  prop="selfTypeValue">
                <el-select
                  style="width: 100%;"
                  multiple
                  v-model="formData.selfTypeValue"
                  filterable
                  remote
                  placeholder="请选择"
                  :remote-method="remoteMethod"
                  :loading="selectLoading"
                  :reserve-keyword="true"
                >
                  <!--:filter-method="selfFilterMethod"-->
                  <el-option
                    v-for="(item,index) in rsAllEmialOptions"
                    :key="index"
                    :label="`${item.email} (${item.userName})`"
                    :value="item.email">
                    <div>
                      {{`${item.email} (${item.userName})`}}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              共{{formData.recipientsType == 1 ? count: formData.selfTypeValue.length}}人
            </el-col>
          </el-row>
        </div>
      </el-form-item>
      <el-form-item
        label="邮件"
        prop="mailSource"
      >
        <div class="type-check">
          <el-radio v-model="formData.mailSource" :label="1">模板</el-radio>
          <el-radio v-model="formData.mailSource" :label="2">自定义</el-radio>
        </div>
      </el-form-item>
      <div v-if="formData.mailSource == 1">
        <div class="select-mailSource-module">
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item prop="mailTemplate">
                <el-select
                  style="width: 100%;"
                  v-model="formData.mailTemplate"
                  filterable
                  placeholder="请选择"
                  @change="mailTemplateHandle"
                >
                  <el-option
                    v-for="(item,index) in emialTemplateOptions"
                    :key="index"
                    :label="item.templateDesc"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="优先级" prop="priority">
                <el-input :readonly="priorityComputed" v-model.number="formData.priority"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <template v-if="formData.mailSource ==2" >
        <div class="select-mailSource-module">
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item label="主题" prop="title">
                <el-input type="textarea" v-model="formData.title" maxlength="100" show-word-limit></el-input>
              </el-form-item>
              <el-form-item
                  label="主题繁体"
                  prop="titleHant"
              >
                <el-input
                    type="textarea"
                    v-model="formData.titleHant"
                    maxlength="100"
                    show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item
                  label="主题英文"
                  prop="titleEn"
              >
                <el-input
                    type="textarea"
                    v-model="formData.titleEn"
                    maxlength="300"
                    show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item label="优先级" prop="selfPriority">
                <el-select
                  style="width: 100%;"
                  v-model="formData.selfPriority"
                  placeholder="请选择 (紧急程度: 1< 2 < 3)"
                >
                  <el-option
                    v-for="(item,index) in selfPriorityOption"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </template>
      <el-form-item v-if="formData.mailSource ==2" label="内容">
        <quill-editor
          @catchData="catchData"
          :defaultValues="formData.mainBodyContent"
        ></quill-editor>
      </el-form-item>
      <el-form-item v-if="formData.mailSource ==2" label="内容繁体">
        <quill-editor
            @catchData="catchDataHant"
            :defaultValues="formData.mainBodyContentHant"
        ></quill-editor>
      </el-form-item>
      <el-form-item
          v-if="formData.mailSource ==2"
          label="内容英文"
      >
        <quill-editor
            @catchData="catchDataEn"
            :defaultValues="formData.mainBodyContentEn"
        ></quill-editor>
      </el-form-item>
    </el-form>
    <div slot="footer" class="btn-module">
      <el-button
          type="text"
          @click="cancleHandle"
      >取消</el-button>
      <el-button
          type="primary"
          :loading="saveLoading"
          @click="saveHandle"
      >保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import quillEditor from '@/components/tools/quillEditor'
  import { queryFromEmailList, queryRsAllEMail, queryEMailTemplateList,queryMassDetail, addMass,computedReceiversCount,editMass } from '@/api/notificationManager/mass'
  import { parseTime } from '@/utils/index'

  export default {
    components: {
      quillEditor
    },
    props: {
      isAdd: {
        type: Boolean,
        default: false
      },
      rowId:{
        type: Number,
        default: 0,
      }
    },
    data() {
      let typeValueValidate = (rule,value,callback) => {
        if(value && value.length){
          callback();
        }else {
          callback(new Error('请选择'));
        }
      };
      let selfTypeValueValidate = (rule,value,callback) => {
        if(value && value.length){
          callback();
        }else {
          callback(new Error('请选择'));
        }
      };
      let priorityValidate = (rule,value,callback) => {
        if([1,2,3].indexOf(value) > -1){
          callback();
        }else {
          callback(new Error('请选择1-3的值'));
        }
      };
      return {
        saveLoading: false,
        dialog: false,
        formData: {
          desc: '',
          emailConfigId: '', //
          recipientsType: 1,
          typeValue: [],// 类型收件人,
          selfTypeValue: [], // 自定义收件人
          mailSource: 1,
          mailTemplate: '',
          priority: '',
          title: '',
          titleEn: '',
          titleHant: '',
          selfPriority: '',
          mainBodyContent: '', //增加多语言的
          mainBodyContentHant: '',
          mainBodyContentEn: '',
        },
        rules: {
          desc: [
            { required: true, message: '请输入描述', trigger: 'blur', }
          ],
          emailConfigId:[
            { required: true, message: '请选择发件人', trigger: 'change', }
          ],
          // recipientsType:[],
          typeValue:[
            { validator: typeValueValidate, trigger: 'change',}
          ],
          selfTypeValue:[
            { validator: selfTypeValueValidate, trigger: 'change',}
          ],
          mailTemplate: [
            { required: true, message: '请选择邮件模板', trigger: 'change', }
          ],// 邮件模板
          // mailSource: [], 不用验证,固定有值
          priority: [
            { validator: priorityValidate, trigger: 'blur', }
          ],
          title: [
            { required: true, message: '请输入主题', trigger: 'blur', }
          ],
          titleHant: [
            { required: true, message: '请输入主题繁体', trigger: 'blur', }
          ],
          titleEn: [
            { required: true, message: '请输入主题英文', trigger: 'blur', }
          ],
          selfPriority: [
            { required: true, message: '请选择优先级', trigger: 'change', }
          ],
          mainBodyContent: ''
        },
        fromEmailList: [],
        cascaderOption: [
          {
            value: 99,
            label: '状态',
            children: [
              {
                value: 10,
                label: '注册未激活'
              },
              {
                value: 1,
                label: '注册未申请'
              },
              {
                value: 2,
                label: '申请未开户'
              },
              {
                value: 3,
                label: '开户未入金'
              },
              {
                value: 4,
                label: '入金未交易'
              },
              {
                value: 5,
                label: '已交易'
              }
            ]
          }, {
            value: 100,
            label: '身份',
            children: [
              {
                value: 1,
                label: '客户1（无上级代理，无客户经理）'
              },
              {
                value: 2,
                label: '客户2（无上级代理，有客户经理）'
              },
              {
                value: 3,
                label: '客户3（有上级代理，有客户经理）'
              },
              {
                value: 6,
                label: '客户4（有上级客户，无客户经理）'
              },
              {
                value: 4,
                label: '代理1（主代理）'
              },
              {
                value: 5,
                label: '代理2（子代理）'
              }
            ]
          }],
        props: { multiple: true },
        rsAllEmialOptions: [],
        emialTemplateOptions: [],
        selfPriorityOption: [
          {
            value: 1,
            label: 1,
          },
          {
            value: 2,
            label: 2,
          },
          {
            value: 3,
            label: 3,
          }
        ],
        count: 0,
        selectLoading: false,
      }
    },
    created() {
      this.getFromEmailList()
      // this.getRsAllEmail()
      this.getEmailTemplateList()
    },
    computed: {
      title() {
        return this.isAdd ? '新增' : '编辑'
      },
      priorityComputed(){
        return this.formData.mailSource == 1;
      }
    },
    watch:{
      dialog(newValue){
        if(newValue && this.rowId && !this.isAdd){
          this.getData();
        }
      },
      'formData.recipientsType'(newValue){
        if(newValue){
          // 没有效果 todo
          this.$nextTick(() => {
            this.$refs.elform.validateField(['typeValue','selfTypeValue']);
          })
        }
      },
      'formData.typeValue'(){
        this.getRecipientsCount();
      },
      'formData.selfTypeValue'(){
        this.getRecipientsCount();
      },
      isAdd(newValue){
        if(newValue){
          this.reset();
        }
      },
    },
    methods: {
      parseTime,
      typeChange(){
        this.$refs.elform.clearValidate(['typeValue','selfTypeValue'])
      },
      close(){
        this.dialog = false;
      },
      remoteMethod(query){
        if (query !== '') {
          this.loading = true;
          queryRsAllEMail({
            userInfo: query,
          }).then((res) => {
            this.loading = false;
            this.rsAllEmialOptions = res
          });
        } else {
          this.rsAllEmialOptions = [];
        }
      },
      getData(){
        queryMassDetail({id: this.rowId}).then((res) => {
          this.id = res.id;
          this.formData.desc = res.describe;
          this.formData.emailConfigId = res.emailConfigId;
          this.formData.recipientsType = res.receiverSource;

          if(res.receiverSource == 1){
            this.formData.typeValue = [];
            if(res.custIdentity){
              let custIdentityArr = res.custIdentity.split(',');
              for (let i = 0; i < custIdentityArr.length; i++) {
                this.formData.typeValue.push([100,+custIdentityArr[i]]);
              };
            }
            if(res.custStatus){
              let custStatusArr = res.custStatus.split(',');
              for (let i = 0; i < custStatusArr.length; i++) {
                this.formData.typeValue.push([99,+custStatusArr[i]]);
              };
            }
            this.formData.priority = res.priority;
          }else {
            this.formData.selfTypeValue = res.toList;
          }
          this.count = res.dueCount;
          this.formData.mailSource = res.mailSource;
          if(res.mailSource == 1){
            this.formData.mailTemplate = res.mailTemplate;
          }else {
            this.formData.title = res.title;
            this.formData.titleHant = res.titleHant;
            this.formData.titleEn = res.titleEn;
            this.formData.selfPriority = res.priority;
            this.formData.mainBodyContent = res.content;
            this.formData.mainBodyContentEn = res.contentEn;
            this.formData.mainBodyContentHant = res.contentHant;
          }
        })
      },
      elCascaderRemoveHandle(){
        this.getRecipientsCount();
      },
      getRecipientsCount() {
        if(this.formData.recipientsType == 1){
          if(this.formData.typeValue.length){
            let statusList = this.geCommon(99);
            let identityList = this.geCommon(100);
            let params = {}
            if(statusList.length){
              params['statusList'] = statusList;
            }
            if(identityList.length){
              params['identityList'] = identityList;
            }
            computedReceiversCount(params).then((res) => {
              this.count = res;
            })
          }else {
            this.count = 0;
          }
        }else {
          if(typeof this.formData.selfTypeValue  == 'array'){
            this.count = this.formData.selfTypeValue.length;
          }else {
            this.count = 0;
          }
        }
      },
      getFromEmailList() {
        queryFromEmailList().then((res) => {
          this.fromEmailList = res
        })
      },
      getRsAllEmail() {
        queryRsAllEMail().then((res) => {
          this.rsAllEmialOptions = res
        })
      },
      getEmailTemplateList() {
        queryEMailTemplateList().then((res) => {
          this.emialTemplateOptions = res
        })
      },
      getLabel(item) {
        let typeText = ''
        switch (item.type) {
          case 0:
            typeText = '通用邮箱'
            break
          case 1:
            typeText = '验证码用途'
            break
          case 2:
            typeText = '跟单提示用途'
            break
        }
        return `${item.fromUser} (${typeText})`
      },
      catchData(value) {
        //在这里接受子组件传过来的参数，赋值给data里的参数
        this.formData.mainBodyContent = value
      },
      catchDataHant(value) {
        //在这里接受子组件传过来的参数，赋值给data里的参数
        this.formData.mainBodyContentHant = value
      },
      catchDataEn(value) {
        //在这里接受子组件传过来的参数，赋值给data里的参数
        this.formData.mainBodyContentEn = value
      },
      cancleHandle(){
        this.close();
      },
      geCommon(number){
        let tempArr = [];
        for (let i = 0; i < this.formData.typeValue.length ; i++) {
          if(this.formData.typeValue[i][0] == number){
            tempArr.push(this.formData.typeValue[i][1]);
          }
        }
        return tempArr;
      },
      getStatusList(){
        return this.geCommon(99);
      },
      getIdentityListList(){
        return this.geCommon(100);
      },
      reset(){
        this.formData = {
          desc: '',
          emailConfigId: '', //
          recipientsType: 1,
          typeValue: [],// 类型收件人,
          selfTypeValue: [], // 自定义收件人
          mailSource: 1,
          mailTemplate: '',
          priority: '',
          title: '',
          titleEn: '',
          titleHant: '',
          selfPriority: '',
          mainBodyContent: '',
          mainBodyContentEn: '',
          mainBodyContentHant: '',
        };
      },
      saveHandle(){
        this.$refs.elform.validate((valid) => {
          if(valid){
            if(this.formData.mailSource == 2 && !this.formData.mainBodyContent){
              this.$message({
                type: 'warning',
                message: '请在富文本输入内容',
              })
              return;
            }
            this.saveLoading = true;
            let params = {
              type: 1,
              describe: this.formData.desc,
              emailConfigId: this.formData.emailConfigId,
              receiverSource: this.formData.recipientsType,
              dueCount: this.formData.recipientsType == 1 ? this.count : this.formData.selfTypeValue.length,
              mailSource: this.formData.mailSource,
            }
            if(this.formData.recipientsType == 1){
              params.statusList = this.getStatusList();
              params.identityList = this.getIdentityListList();
            }else {
              params.toList = this.formData.selfTypeValue;
            }
            if(this.formData.mailSource == 1){
              params.mailTemplate = this.formData.mailTemplate;
              params.priority = this.formData.priority;
            }else {
              params.title = this.formData.title;
              params.titleHant = this.formData.titleHant;
              params.titleEn = this.formData.titleEn;
              params.priority = this.formData.selfPriority;
              params.content = this.formData.mainBodyContent;
              params.contentEn = this.formData.mainBodyContentEn;
              params.contentHant = this.formData.mainBodyContentHant;
            }
            if(!this.isAdd && this.rowId){
              params.id = this.rowId;
              editMass(params).then((res) => {
                this.saveLoading = false;
                this.$message({
                  type: 'success',
                  message: '保存成功',
                });
                this.$parent.init();
                setTimeout(() => {
                  this.close();
                  this.reset();
                },1000)
              }).catch((error) => {
                this.saveLoading = false;
              })
            }else {
              addMass(params).then((res) => {
                this.saveLoading = false;
                this.$message({
                  type: 'success',
                  message: '保存成功',
                });
                this.$parent.init();
                setTimeout(() => {
                  this.close();
                  this.reset();
                },1000)
              }).catch((error) => {
                this.saveLoading = false;
              })
            }
          }else {
            console.log('不通过');
          }
        });
      },
      mailTemplateHandle(value){
        for (let i = 0; i <this.emialTemplateOptions.length ; i++) {
          if(value == this.emialTemplateOptions[i].id){
            this.formData.priority = this.emialTemplateOptions[i].priority;
          }
        }
      },
    }
  }
</script>

<style scoped>

</style>
